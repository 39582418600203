import { loadPolyfills } from './polyfills';
import { loadIcons } from 'Shared/icons';
import { init as initI18n, register, waitLocale } from 'svelte-i18n';
import { defaultConfig } from 'Shared/default-config';

async function init() {
  await loadPolyfills();
  await loadIcons();
  await loadLocale();
  await loadOneplayer();
}

async function loadLocale() {
  // Do stuff with locale and languages
  register('en', async () => await import('./i18n/en'));
  register('nl', async () => await import('./i18n/nl'));
  register('es', async () => await import('./i18n/es'));
  register('de', async () => await import('./i18n/de'));

  await initI18n({
    fallbackLocale: defaultConfig.language.toString(),
  });

  return waitLocale();
}

async function loadOneplayer() {
  const onePlayerElement = (await import('./one-player-element')).default;
  window.customElements.define('one-player', onePlayerElement);
}

// eslint-disable-next-line @typescript-eslint/no-floating-promises
init();
